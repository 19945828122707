<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10"
      y="18.125"
      width="4"
      height="4.75"
      rx="2"
      fill="currentColor"
    />
    <mask
      id="mask0_895_8675"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="4"
      width="14"
      height="7"
    >
      <rect
        x="5"
        y="4.125"
        width="14"
        height="6"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_895_8675)">
      <path
        d="M18 10.625C18 13.5941 15.3848 16.125 12 16.125C8.61521 16.125 6 13.5941 6 10.625C6 7.65593 8.61521 5.125 12 5.125C15.3848 5.125 18 7.65593 18 10.625Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
    <rect
      x="10.5"
      y="1.125"
      width="3"
      height="5"
      rx="1.5"
      fill="currentColor"
    />
    <path
      d="M19 17.145L20.9893 19.1365V20.125H19V17.145Z"
      fill="currentColor"
    />
    <path
      d="M5 17.145L3.01074 19.1365V20.125H5V17.145Z"
      fill="currentColor"
    />
    <rect
      x="16.9453"
      y="10.125"
      width="2.05371"
      height="10"
      fill="currentColor"
    />
    <rect
      x="5"
      y="10.125"
      width="2.05957"
      height="10"
      fill="currentColor"
    />
    <rect
      x="19"
      y="18.125"
      width="2"
      height="14"
      transform="rotate(90 19 18.125)"
      fill="currentColor"
    />
    <rect
      x="11"
      y="7.99951"
      width="2"
      height="4"
      fill="currentColor"
    />
    <rect
      x="11"
      y="14.0623"
      width="2"
      height="2"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcBellAlert',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
